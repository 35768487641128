import Web3 from 'web3';
import {getBalanceOf, gettotalSupply} from './space.js'
import BigNumber from 'bignumber.js'
var farmData = window.farmData.lpPools
var tokensList =  window.farmData.tokenList
var NewFarm =  window.farmData.singlePools.concat(window.farmData.lpPools)

// farmData.forEach((item,index) => {
//     if (item.coin.tokenB.contract == tokensList[0].contract) {
//         getBalanceOf(item.coin.tokenA.contract,item.coin.contract).then(balanceA => {
//             var lpANum = BigNumber(balanceA).div(Math.pow(10,item.coin.tokenA.decimals))
//             console.log(item.coin.tokenA.name,lpANum.toString())
//             getBalanceOf(item.coin.tokenB.contract,item.coin.contract).then(balanceB => {
//                 var lpBNum = BigNumber(balanceB).div(Math.pow(10,item.coin.tokenB.decimals))
//                 console.log(item.coin.tokenB.name,lpBNum.toString())
//                 tokensList.forEach((tokenItem,tokenIndex) => {
//                     if (tokenItem.contract == item.coin.tokenA.contract) {
//                         tokenItem.price = BigNumber(lpBNum).div(lpANum).times(tokensList[0].price).toString()
//                         window.farmData.tokenList = tokensList
//                     }
//                 })
//             })
//         })
//     }
// });
getBalanceOf('0xE81f86bB828933bb67CD6078a35f0e6eEf24Af08','0x1Befd2e673f7F152865c1a48771eF2e647Ff159D').then(tSPACESTGUSDCA => {
    var lpANum = BigNumber(tSPACESTGUSDCA).div(Math.pow(10,18))
    getBalanceOf('0x6581e59A1C8dA66eD0D313a0d4029DcE2F746Cc5','0x1Befd2e673f7F152865c1a48771eF2e647Ff159D').then(tSPACESTGUSDCB => {
        var lpBNum = BigNumber(tSPACESTGUSDCB).div(Math.pow(10,18))
        tokensList[1].price = BigNumber(lpBNum).div(lpANum).times(tokensList[0].price).toString()
        window.farmData.tokenList = tokensList
    })
})
getBalanceOf('0x5806E416dA447b267cEA759358cF22Cc41FAE80F','0xaa56aE63b598ef2428dc82Cc383F79dcf37C92ed').then(WBERASTGUSDCA => {
    var lpANum = BigNumber(WBERASTGUSDCA).div(Math.pow(10,18))
    getBalanceOf('0x6581e59A1C8dA66eD0D313a0d4029DcE2F746Cc5','0xaa56aE63b598ef2428dc82Cc383F79dcf37C92ed').then(WBERASTGUSDCB => {
        var lpBNum = BigNumber(WBERASTGUSDCB).div(Math.pow(10,18))
        tokensList[5].price = BigNumber(lpBNum).div(lpANum).times(1).toString()
        getBalanceOf('0x5806E416dA447b267cEA759358cF22Cc41FAE80F','0x30725F8BA2eB5432179EC8BDFA00d8EAf216c407').then(WBERAHONEYA => {
            var lpANum = BigNumber(WBERAHONEYA).div(Math.pow(10,18))
            getBalanceOf('0x7EeCA4205fF31f947EdBd49195a7A88E6A91161B','0x30725F8BA2eB5432179EC8BDFA00d8EAf216c407').then(WBERAHONEYB => {
                var lpBNum = BigNumber(WBERAHONEYB).div(Math.pow(10,18))
                tokensList[2].price = lpANum*tokensList[5].price/lpBNum
                getBalanceOf('0x9DAD8A1F64692adeB74ACa26129e0F16897fF4BB','0x35bCdBe20068b8DF453843467BAf4628C6B62790').then(WBTCHONEYA => {
                    var lpANum = BigNumber(WBTCHONEYA).div(Math.pow(10,8))
                    getBalanceOf('0x7EeCA4205fF31f947EdBd49195a7A88E6A91161B','0x35bCdBe20068b8DF453843467BAf4628C6B62790').then(WBTCHONEYB => {
                        var lpBNum = BigNumber(WBTCHONEYB).div(Math.pow(10,18))
                        tokensList[3].price = BigNumber(lpBNum).div(lpANum).times(tokensList[2].price).toString()
                    })
                })
                getBalanceOf('0x8239FBb3e3D0C2cDFd7888D8aF7701240Ac4DcA4','0x01502d61804e0c39dcdb2855C0F41777102304Db').then(WETCHONEYA => {
                    var lpANum = BigNumber(WETCHONEYA).div(Math.pow(10,18))
                    getBalanceOf('0x7EeCA4205fF31f947EdBd49195a7A88E6A91161B','0x01502d61804e0c39dcdb2855C0F41777102304Db').then(WETCHONEYB => {
                        var lpBNum = BigNumber(WETCHONEYB).div(Math.pow(10,18))
                        tokensList[4].price = BigNumber(lpBNum).div(lpANum).times(tokensList[2].price).toString()
                        window.farmData.tokenList = tokensList
                    })
                })
            })
        })
    })
})

var time_ = setInterval(() => {
    for (let i = 0; i < window.farmData.tokenList.length; i++) {
        if (window.farmData.tokenList[i].price) {
            if (i == window.farmData.tokenList.length-1) {
                clearInterval(time_)
                NewFarm[0].price = tokensList[1].price
                getgettotalSupply(NewFarm[1].coin.contract).then(totalAll => {
                    var _totalAll =  BigNumber(totalAll).div(Math.pow(10,NewFarm[1].coin.decimals))
                    getBalanceOf(NewFarm[1].coin.tokenA.contract,NewFarm[1].coin.contract).then(lpAB => {
                        var _lpAB = BigNumber(lpAB).div(Math.pow(10,NewFarm[1].coin.tokenA.decimals)).times(tokensList[5].price)
                        getBalanceOf(NewFarm[1].coin.tokenB.contract,NewFarm[1].coin.contract).then(lpBB => {
                            var _lpBB = BigNumber(lpBB).div((Math.pow(10,NewFarm[1].coin.tokenB.decimals))).times(tokensList[2].price)
                            NewFarm[1].price = BigNumber(_lpAB).plus(_lpBB).div(_totalAll).toString()
                        })
                    })
                })
                getgettotalSupply(NewFarm[2].coin.contract).then(totalAll => {
                    var _totalAll =  BigNumber(totalAll).div(Math.pow(10,NewFarm[2].coin.decimals))
                    getBalanceOf(NewFarm[2].coin.tokenA.contract,NewFarm[2].coin.contract).then(lpAB => {
                        var _lpAB = BigNumber(lpAB).div(Math.pow(10,NewFarm[2].coin.tokenA.decimals)).times(tokensList[5].price)
                        getBalanceOf(NewFarm[2].coin.tokenB.contract,NewFarm[2].coin.contract).then(lpBB => {
                            var _lpBB = BigNumber(lpBB).div((Math.pow(10,NewFarm[2].coin.tokenB.decimals))).times(tokensList[0].price)
                            NewFarm[2].price = BigNumber(_lpAB).plus(_lpBB).div(_totalAll).toString()
                        })
                    })
                })
                getgettotalSupply(NewFarm[3].coin.contract).then(totalAll => {
                    var _totalAll =  BigNumber(totalAll).div(Math.pow(10,NewFarm[3].coin.decimals))
                    getBalanceOf(NewFarm[3].coin.tokenA.contract,NewFarm[3].coin.contract).then(lpAB => {
                        var _lpAB = BigNumber(lpAB).div(Math.pow(10,NewFarm[3].coin.tokenA.decimals)).times(tokensList[1].price)
                        getBalanceOf(NewFarm[3].coin.tokenB.contract,NewFarm[3].coin.contract).then(lpBB => {
                            var _lpBB = BigNumber(lpBB).div((Math.pow(10,NewFarm[3].coin.tokenB.decimals))).times(tokensList[5].price)
                            NewFarm[3].price = BigNumber(_lpAB).plus(_lpBB).div(_totalAll).toString()
                        })
                    })
                })
                getgettotalSupply(NewFarm[4].coin.contract).then(totalAll => {
                    var _totalAll =  BigNumber(totalAll).div(Math.pow(10,NewFarm[4].coin.decimals))
                    getBalanceOf(NewFarm[4].coin.tokenA.contract,NewFarm[4].coin.contract).then(lpAB => {
                        var _lpAB = BigNumber(lpAB).div(Math.pow(10,NewFarm[4].coin.tokenA.decimals)).times(tokensList[1].price)
                        getBalanceOf(NewFarm[4].coin.tokenB.contract,NewFarm[4].coin.contract).then(lpBB => {
                            var _lpBB = BigNumber(lpBB).div((Math.pow(10,NewFarm[4].coin.tokenB.decimals))).times(tokensList[2].price)
                            NewFarm[4].price = BigNumber(_lpAB).plus(_lpBB).div(_totalAll).toString()
                        })
                    })
                })
                getgettotalSupply(NewFarm[5].coin.contract).then(totalAll => {
                    var _totalAll =  BigNumber(totalAll).div(Math.pow(10,NewFarm[5].coin.decimals))
                    getBalanceOf(NewFarm[5].coin.tokenA.contract,NewFarm[3].coin.contract).then(lpAB => {
                        var _lpAB = BigNumber(lpAB).div(Math.pow(10,NewFarm[5].coin.tokenA.decimals)).times(tokensList[0].price)
                        getBalanceOf(NewFarm[5].coin.tokenB.contract,NewFarm[3].coin.contract).then(lpBB => {
                            var _lpBB = BigNumber(lpBB).div((Math.pow(10,NewFarm[5].coin.tokenB.decimals))).times(tokensList[2].price)
                            NewFarm[5].price = BigNumber(_lpAB).plus(_lpBB).div(_totalAll).toString()
                        })
                    })
                })
                
                window.newFarmData = NewFarm
            }
        } else {
            return false
        }
        
    }
},500)
function getgettotalSupply(addr1){
    return new Promise((resolve, reject) => {
        gettotalSupply(addr1).then(res => {
            resolve( res)
        })
    })
}