import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        demoValue:{},
        gasBalance:0.000,
        gasToken:[
            {
              select:false,
              symbol:'ETH',
              address:'0x0000000000000000000000000000000000000000',
              decimals:18,
              discount:0,
              icon:'https://arweave.net/5MbY31PcSQip4Rrvk0OaquQBaGSzPMWirW8U_cIKwZ4'
            },{
              select:false,
              symbol:'USDC',
              address:'0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
              decimals:6,
              discount:0,
              icon:'https://arweave.net/8TojFbaI5NOEoDMlhbBmKwtiKOc9PPiOqxNnR80T4yA'
            },{
              select:false,
              symbol:'USDT',
              address:'0x493257fD37EDB34451f62EDf8D2a0C418852bA4C',
              decimals:6,
              discount:0,
              icon:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0x493257fd37edb34451f62edf8d2a0c418852ba4c.png'
            },{
              select:false,
              symbol:'SPACE',
              address:'0x47260090cE5e83454d5f05A0AbbB2C953835f777',
              decimals:18,
              discount:20,
              icon:'https://arweave.net/jA49izIQ8xCiSTnYe2MuhvfFdd94M99DgrKYQp07vOE'
            },{
              select:false,
              symbol:'STAR',
              address:'0xE81f86bB828933bb67CD6078a35f0e6eEf24Af08',
              decimals:18,
              discount:0,
              icon:'https://arweave.net/jA49izIQ8xCiSTnYe2MuhvfFdd94M99DgrKYQp07vOE'
            },{
              select:false,
              symbol:'HOLD',
              address:'0xed4040fD47629e7c8FBB7DA76bb50B3e7695F0f2',
              decimals:18,
              discount:20,
              icon:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0xed4040fD47629e7c8FBB7DA76bb50B3e7695F0f2.png'
            },
        ]
    },

    mutations: {

    },

    getters: {
        getDemoValue: state => state.demoValue
    },

    actions: {

    },

    modules: {
        setDemoValue(state,demoValue){
            state.demoValue = demoValue
        }
    }
})
