import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Web3 from 'web3';
import store from './store/index.js';
import axios from '@/utils/axios.js'
import './css/font.css';
import '@/css/reset.scss'
import '@/css/component.scss'
import '@/css/view.scss'
import '@/utils/space.js'
import '@/utils/getPrice.js'

import headerTopTip from '@/components/HeaderTopTip.vue'
Vue.component('v-HeaderTopTip', headerTopTip)
import header from '@/components/Header.vue'
Vue.component('v-Header', header)
import footer from '@/components/Footer.vue'
Vue.component('v-Footer', footer)
import NumberGrow from '@/components/numberGrow.vue'
Vue.component('v-NumberGrow', NumberGrow)
import LoadingWarp from '@/components/loading.vue'
Vue.component('v-LoadingWarp', LoadingWarp)
import LoadingWarpBlack from '@/components/loadingBlack.vue'
Vue.component('v-LoadingWarpBlack', LoadingWarpBlack)
import noticeWarp from '@/components/Notice.vue'
Vue.component('v-NoticeWarp', noticeWarp)

import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
Vue.use(ElementUI);

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)

localStorage.setItem('chainId',Web3.utils.toHex(80085))
localStorage.setItem('chainName','Berachain Artio')
localStorage.setItem('nativeCurrencyName','BERA')
localStorage.setItem('nativeCurrencySymbol','BERA')
localStorage.setItem('rpcUrls',JSON.stringify(['https://artio.rpc.berachain.com']))
localStorage.setItem('blockExplorerUrls',JSON.stringify(['https://artio.beratrail.io']))


Vue.prototype.$store =  store
Vue.prototype.$axios = axios;
Vue.prototype.$BigNumber = require('bignumber.js')
Vue.prototype.$isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)

Vue.config.productionTip = false

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')
