<template>
    <div class="loading-warp">
        <img v-if="imgSize == 3" :data-value="imgSize" class="imgBig" src="@/assets/logos/spacefiFFF.png" alt="">
        <img v-if="imgSize == 2" :data-value="imgSize" class="imgMiddle" src="@/assets/logos/spacefiFFF.png" alt="">
        <img v-if="imgSize == 1" :data-value="imgSize" class="imgSmall" src="@/assets/logos/spacefiFFF.png" alt="">
    </div>
</template>

<script>
export default {
    name: 'LoadingWarp',
    props: {
        imgSize: {
            type: Number,
            default: true
        },
    },
    data() {
        return {
            
        }
    },
    mounted() {

    }
}
</script>
<style scoped lang="scss">
.loading-warp{
    display: flex;
    align-items: center;
    img{
        display: inline-block;
        // margin: 0 10px;
        animation: rotateAaimation 1s infinite;
    }
    .imgBig{
        width: 60px;
        height: 60px;
    }
    .imgMiddle{
        width: 40px;
        height: 40px;
    }
    .imgSmall{
        width: 20px;
        height: 20px;
    }
    @keyframes rotateAaimation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}

</style>