<template>
	<div id="app">
		<!-- <v-HeaderTopTip></v-HeaderTopTip> -->
		<v-Header :headerType='headerType'></v-Header>
		<router-view/>
		<!-- <div :class="goTopShow ? 'suspension suspensionAction' : 'suspension'">
			<a class="go2_0" href="https://app-v1.spacefi.io/" target="_self">
				<img src="@/assets/icons/switch.png" alt="">
				<span>Switch 1.0</span>
			</a>
			<div class="goTop" @click="goTop">
				<img src="@/assets/icons/top.png" alt="">
			</div>
		</div> -->
		<!-- <v-NoticeWarp></v-NoticeWarp> -->
	</div>
</template>

<script>
  export default {
	watch: {
		$route: {
			handler: function(val, oldVal){
				if (val.name == 'nftDetail') {
					localStorage.setItem('tonftdetailname',oldVal.name)
				}
				// if (val.name == 'Mint' || val.name == 'Staking' || val.name == 'Marketplace' || val.name == 'MyNFT' || val.name == 'nftDetail' ) {
				// 	this.headerType = 1
				// } else {
					this.headerType = 0
				// }
			},
			deep: true
		}
	},
    data(){
        return {
			headerType: 0,
			footerShow: false,
			goTopShow:false,
        }
    },
    mounted(e){
        var userAgent = navigator.userAgent
        if (userAgent.indexOf("Firefox") > -1) {
            $('#app').css( 'width','calc( 100vw - 15px)' )
        }
		window.onscroll = ()=>{
			var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
			var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
			
			if(scrollTop > windowHeight){
				this.goTopShow = true
			} else {
				this.goTopShow = false
			}

        }
    },
	methods:{
		goTop(){
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			})
		}
	}
  }
</script>

<style lang="scss"></style>
